.container {
    position: relative;
    /* The container determains the size. */
    width: 1200px;
    height: 809px;
}

.container > .loading-overlay {
/* We'll render the overlay on top of the Unity Application. */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: grey;
    /* We'll set the following Flex properties in order to center the text. */
    display: flex;
    justify-content: center;
    align-items: center;
}

.unity {
/* The Unity Application matches it size to the container. */
    width: 100%;
    height: 100%;
}